<template>
  <div>
    <div style="z-index:99;">
      <b-carousel
        id="carousel-1"
        :interval="4000"
        background="#ababab"
        style="text-shadow: 1px 1px 2px #333;"
      >
        <b-carousel-slide
          img-src="../img/home_carousel/kgo_carousel-1@0.5x.jpg"
          img-alt="Klub Go nad Odrą first carousel image"
        ></b-carousel-slide>
        <b-carousel-slide
          img-src="../img/home_carousel/kgo_carousel-2@0.5x.jpg"
          alt="Klub Go nad Odrą second carousel image"
        ></b-carousel-slide>
        <b-carousel-slide
          img-src="../img/home_carousel/kgo_carousel-3@0.5x.jpg"
          alt="Klub Go nad Odrą third carousel image"
        ></b-carousel-slide>
      </b-carousel>
    </div>

    <b-container align-h="center" id="home-div">
      <div style="z-index:100;">
        <b-alert style="text-align: left;" variant="success" show dismissible>
          <h4 class="alert-heading">Klub Go nad Odrą w nowej lokalizacji!</h4>
          <p>
            Miło nam poinformować, że otwieramy trzecią lokalizację Klubu Go nad Odrą we Wrocławiu! <b-link to="/contact">Znajdź informacje o niej tutaj!</b-link>
          </p>
        </b-alert>
      </div>
      <div>
        <h1>
          Wydarzenia
        </h1>
        <b-form @submit.prevent="search" class="justify-content-end" inline>
          <b-input-group label-align-sm="right">
            <b-form-input
              placeholder="Nazwa wydarzenia"
              v-model="searchText"
            ></b-form-input>
            <b-input-group-append>
              <b-button
                style="background-color: #cc9966; border-color: #cc9966 !important;"
                type="submit"
              >
                Szukaj
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form>
      </div>
      <hr />

      <b-row align-h="center">
        <news-card
          :name="news.name"
          :date="news.date"
          :id="news.id"
          :imgLink="news.imgLink"
          :text="news.text"
          :roundsBoardLink="news.roundsBoardLink"
          :fbLink="news.fbLink"
          :egdLink="news.egdLink"
          :registrationLink="news.registrationLink"
          :slug="news.slug"
          :registrationCount="news.registrationCount"
          v-for="news in getDisplayNews"
          :key="news.id"
        ></news-card>
      </b-row>

      <hr />

      <b-pagination
        pills
        align="center"
        v-model="currentPage"
        :total-rows="getRows"
        :per-page="perPage"
        @input="paginate(currentPage)"
      ></b-pagination>
    </b-container>
  </div>
</template>

<script>
// @ is an alias to /src
import NewsCard from "@/components/NewsCard.vue";
import { mapGetters } from "vuex";

if (location.search) location.replace(location.href.replace(/\?.+/, ""));

export default {
  name: "home",
  async mounted() {
    await this.getRecords();
  },
  data() {
    return {
      currentPage: 1,
      perPage: 6
    };
  },

  components: { "news-card": NewsCard },

  computed: {
    ...mapGetters(["getRows", "getDisplayNews"])
  },

  methods: {
    paginate(currentPage) {
      this.$store.dispatch("paginate", { currentPage, perPage: this.perPage });
    },
    async getRecords() {
      await this.$store.dispatch("fetchNews");
    },
    search() {
      this.$store.dispatch("search", { text: this.searchText });
    },
    data() {
      return {
        searchText: ""
      };
    }
  },

  metaInfo: {
    title: "Wydarzenia",
    titleTemplate: "%s - Klub Go nad Odrą",
    meta: [
      {
        name: "description",
        content:
          "Klub Go nad Odrą istnieje od wiosny 2019 roku i zrzesza wrocławskich goistów, kontynuując w ten sposób tradycję wcześniejszych klubów."
      }
    ]
  }
};
</script>

<style lang="scss" scoped>
#home-div {
  background-color: white;
  opacity: 1;
  margin-top: -30%;
  border-radius: 6px;
  z-index: 100;
  position: relative;
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14),
    0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
  padding-top: 30px;
  padding-bottom: 5px;
}
</style>
