<script>
import TopHeader from "@/components/TopHeader.vue";
import BottomFooter from "@/components/BotomFooter.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    TopHeader: TopHeader,
    BottomFooter: BottomFooter
  },
  computed: {
    ...mapGetters(["getSpinner"])
  },

  created() {
    if (this.$workbox) {
      this.$workbox.addEventListener("waiting", () => {
        this.showUpgradeUI = true;
      });
    }
  },

  methods: {
    async accept() {
      this.showUpgradeUI = false;
      await this.$workbox.messageSW({ type: "SKIP_WAITING" });
    }
  }
};
</script>

<template>
  <div id="app">
    <div class="page" v-if="getSpinner">
      <b-spinner
        class="spinner"
        :variant="'primary'"
        :key="'primary'"
      ></b-spinner>
    </div>
    <div id="nav">
      <TopHeader />
    </div>

    <router-view style="padding-top: 50px;" />

    <div id="footer">
      <BottomFooter />
    </div>
  </div>
</template>

<style lang="scss">
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.page {
  position: absolute;
  background: rgba(0, 0, 0, 0.3);
  z-index: 25;
  width: 100%;
  height: 100%;
}

.spinner {
  z-index: 26;
  position: relative;
  top: 50%;
}
</style>
