import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    // News
    news: [],
    displayNews: [],
    rows: 0,
    showSpinner: false,

    members: [],
    dictionary: [],
    meetings: [],
    gobus: []
  },
  mutations: {
    // News
    SET_NEWS(state, news) {
      state.news = news;
    },
    SET_ROWS(state, rows) {
      state.rows = rows;
    },
    SET_DISPLAY_NEWS(state, displayNews) {
      state.displayNews = displayNews;
    },
    SET_SPINNER(state, spinner) {
      state.showSpinner = spinner;
    },

    // Members
    SET_MEMBERS(state, members) {
      state.members = members;
    },

    // Dictionary
    SET_DICTIONARY(state, dictionary) {
      state.dictionary = dictionary;
    },

    // Meetings
    SET_MEETINGS(state, meetings) {
      state.meetings = meetings;
    },

    // GoBus
    SET_GOBUS(state, gobus) {
      state.gobus = gobus
    }
  },

  actions: {
    // News
    async fetchData({ commit }) {
      commit("SET_SPINNER", true);
      return new Promise(resolve => {
        setTimeout(async () => {
          const res = await fetch("https://server088436.nazwa.pl/kgo/GoHelperAPI/public/api/v1/news");
          const news = await res.json();

          await news.forEach(novelty => {
            if (novelty.slug !== "undefined" && novelty.slug !== null) {
              axios
                .get(
                  "https://api.roundsboard.com/tournaments/" + novelty.slug
                )
                .then(response => {
                  novelty.registrationCount = parseInt(response.data.registrations_count);
                })
                .catch(function(error) {
                  // console.log(error);
                });
            }
          });

          resolve(news);
          commit("SET_SPINNER", false);
        }, 0);
      });
    },

    updatePagination({ commit, dispatch }, { myJson, currentPage, perPage }) {
      commit("SET_NEWS", myJson);
      commit("SET_ROWS", myJson.length);
      dispatch("paginate", { currentPage, perPage });
    },
    async fetchNews({ dispatch }) {
      const myJson = await dispatch("fetchData");
      dispatch("updatePagination", { myJson, currentPage: 1, perPage: 6 });
      return myJson;
    },
    async paginate({ commit, state }, { currentPage, perPage }) {
      const start = (currentPage - 1) * perPage;
      const news = state.news.slice(start, start + 6);
      commit("SET_DISPLAY_NEWS", news);
    },
    async search({ dispatch }, { text }) {
      const myJson = await dispatch("fetchData");
      const values = myJson.filter(val => {
        return val.name.toLowerCase().includes(text.toLowerCase());
      });

      dispatch("updatePagination", {
        myJson: values,
        currentPage: 1,
        perPage: 6
      });
    },

    // Members
    async fetchMembers({ dispatch }) {
      const membersJson = await dispatch("fetchDataMembers");
      dispatch("updatePaginationMembers", { membersJson });
      return membersJson;
    },
    updatePaginationMembers({ commit }, { membersJson }) {
      commit("SET_MEMBERS", membersJson);
    },
    async fetchDataMembers({ commit }) {
      // commit("SET_SPINNER", true);
      return new Promise(resolve => {
        setTimeout(async () => {
          const res = await fetch("https://server088436.nazwa.pl/kgo/GoHelperAPI/public/api/v1/members");
          const members = await res.json();

          await members.forEach(member => {
            if (member.egd !== "") {
              axios
                .get(
                  "https://www.europeangodatabase.eu/EGD/GetPlayerDataByPIN.php?pin=" +
                    member.egd
                )
                .then(response => {
                  member.rank = response.data.Grade;
                  member.gor = parseInt(response.data.Gor);
                })
                .catch(function(error) {
                  // console.log(error);
                });
            }
          });

          resolve(members);
          // commit("SET_SPINNER", false);
        }, 0);
      });
    },
    // Dictionary
    async fetchDictionary({ dispatch }) {
      const dictionaryJson = await dispatch("fetchDataDictionary");
      dispatch("updatePaginationDictionary", { dictionaryJson });
      return dictionaryJson;
    },
    updatePaginationDictionary({ commit }, { dictionaryJson }) {
      commit("SET_DICTIONARY", dictionaryJson);
    },
    async fetchDataDictionary({ commit }) {
      // commit("SET_SPINNER", true);
      return new Promise(resolve => {
        setTimeout(async () => {
          const res = await fetch("dictionary.json");
          const val = await res.json();
          resolve(val);
          // commit("SET_SPINNER", false);
        }, 0);
      });
    },
    // Meetings
    async fetchMeetings({ dispatch }) {
      const meetingsJson = await dispatch("fetchDataMeetings");
      dispatch("updatePaginationMeetings", { meetingsJson });
      return meetingsJson;
    },
    updatePaginationMeetings({ commit }, { meetingsJson }) {
      commit("SET_MEETINGS", meetingsJson);
    },
    async fetchDataMeetings({ commit }) {
      // commit("SET_SPINNER", true);
      return new Promise(resolve => {
        setTimeout(async () => {
          const res = await fetch("meetings.json");
          const val = await res.json();
          resolve(val);
          // commit("SET_SPINNER", false);
        }, 0);
      });
    },
    // GoBus
    async fetchGoBus({ dispatch }) {
      const goBusJson = await dispatch("fetchDataGoBus");
      dispatch("updatePaginationGoBus", { goBusJson });
      return goBusJson;
    },
    updatePaginationGoBus({ commit }, { goBusJson }) {
      commit("SET_GOBUS", goBusJson);
    },
    async fetchDataGoBus({ commit }) {
      commit("SET_SPINNER", true);
      return new Promise(resolve => {
        setTimeout(async () => {
          const res = await fetch("https://server088436.nazwa.pl/kgo/GoHelperAPI/public/api/v1/gobus");
          const val = await res.json();
          resolve(val);
          commit("SET_SPINNER", false);
        }, 0);
      });
    }
  },

  getters: {
    getRows(state) {
      return state.rows;
    },
    getDisplayNews(state) {
      return state.displayNews;
    },
    getSpinner(state) {
      return state.showSpinner;
    },
    getNews(state) {
      return state.news;
    },
    getMembers(state) {
      const members = state.members;

      // Backend function!
      members.sort((member1, member2) => {
        if (member1.activity < member2.activity) {
          return -1;
        }
        if (member1.activity > member2.activity) {
          return 1;
        }
        return 0;
      });
      // ------------------

      return members;
    },
    getDictionary(state) {
      return state.dictionary;
    },
    getMeetings(state) {
      return state.meetings;
    },
    getGoBus(state) {
      return state.gobus;
    }
  },

  modules: {}
});
